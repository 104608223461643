import classes from './Marketing.module.css'

const Marketing = () => {
  return (
    <div className={classes.marketingPage}>
      <h1>
        ŽELITE REKLAMU NA PRAVOM MESTU !? <br />
        NALAZITE SE UPRAVO NA ISTOM !
      </h1>
      <h2>DA LI JE SADA TRENUTAK ZA TO ? DA, I TO NAJBOLJI !</h2>
      <p>
        Ovu priliku nesmete propustiti, ukoliko imate sportsku kladionicu,
        online shop sportske opreme ili poslujete u bilo kojoj drugoj branši, a
        želite da vašu marketinšku poruku vidi veliki broj ljudi !
      </p>
      <p>
        Naš sajt je genijalna ideja u kontinuiranom i u stalnom razvoju, sa
        jasnom tendencijom i ciljem, da postane neprikosnovena društvena mreža
        na Balkanu I znatno šire, bazirana na temi sporta i prognoze sportskih
        rezultata, što će se i desiti !
      </p>
      <p>
        U skladu sa razvojnim fazama, betkotip.com vam je spremio najpovoljnije
        moguće uslove za izlaganje marketinških poruka za vaše proizvode i za
        vaše usluge !
      </p>

      <p>
        Nudimo Vam idealne cene marketinškog prostora, već danas, dok u skladu
        sa rastom poseta na betkotip.com ti uslovi koliko već sutra, neće biti
        jednako dobri i isti !
      </p>

      <p>
        Potpišite dugoročni ugovor pod najboljim mogućim uslovima !
        
      </p>
        <p className={classes.blueP}>

         Zašto da čekate sutra,ako danas imate savršenu ponudu !?
        </p>

      <h2>
        GDE SVE MOŽETE DA POSTAVITE SVOJU REKLAMU NA NAŠEM SAJTU, I KAKO ?
      </h2>

      <p>
        Hajde najpre da vam kažemo, da se sve Vaše marketinške poruke na
        betkotip.com linkuju ka vašem sajtu, mobilnoj aplikaciji ili mestu ka
        kom sami to želite. Takođe, postavka podrazumeva vašu fotografiju, logo
        ili šta god što želite da postavite za svoju marketišku poruku ka
        korisnicima i posetiocima betkotip.com sajta/platforme i betkotip.com
        mobilne aplikacije istovremeno.
      </p>

      <p>
        Na betkotip.com postoji ogroman marketinški prostor koji Vam je dostupan
        na korišćenje. To podrazumeva Vaš marketing i na web verziji sajta, kao
        i na mobilnoj aplikaciji betkotip,com na mobilnim telefonima baziranim
        na android platformi, koja čini 85% od ukupnog korisničkog tržišta
        upotrebe mobilnih telefona.
      </p>

      <p>
        Na centralnoj stranici betkotip.com platforme i mobilne aplikacije,
        možete imati svoju reklamu, a prostor na tom delu je u prikazu je
        dostupan 0-24h, na svim stranicama betkotip.com sajjta/platforme i
        betkotip mobilne aplikacije. Dakle, sve marketinške poruke na ovom delu
        sajta podrazumevaju prikaz korisnicima na kojoj god stranici sajta da se
        isti nalaze.
      </p>

      <p>
        Koje su to države u kojima ljudi mogu da vide vašu postavku reklame na
        centralnoj stranici betkotip.com ? Mogu da je vide u svim državama, na
        celom svetu ! Naš softwer može istovremeno da pokrije više država sa
        različitim marketinškim porukama na betkotip.com , te će ukoliko želite
        da se reklamirate u određenoj državi, vaša reklamna poruka biti vidljiva
        samo u istoj. Na taj način sami birate korisničku ciljnu grupu kojoj
        želite da se obratite svojom marketinškom porukom !
      </p>

      <p>
        Takođe, dostupan vam je i marketinški prostor na preko 350 stranica
        našeg sajta i mobilne aplikacije, i to potpuno besplatno, kao zakupcu
        prostora na centralnoj stranici našeg sajta i mobilne aplikacije. U
        proporcijama sa vašim učešćem u zakupu istog, dobijate procentualno
        učešće i na mnogim od 350 stranica našeg sajta i mobilne aplikacije !
      </p>

      <h2>DA LI JE TO SVE !? NE TO NIJE SVE !</h2>

      <p>
        Posebne pogodnosti nudimo svim poslovnim saradnicima koji žele da podrže
        naš projekat, uključujući i dodatne povoljnosti u okviru uslova za
        postavku Vaših marketinških poruka na naš sajt i na našu mobilnu
        aplikaciju !
      </p>

      <p>
        Za sve pravne subjekte, uključujući i sportske kladionice, i online
        shopove, koji žele da sarađuju sa nama u našim takmičarskim igrama,
        poput takmičarske igre u okviru programa "OTVORI I OSVOJI" - MISTER
        TIPSTER , imamo specijalnu ponudu !
      </p>

      <p>
        Učešćem u saradnji kroz fond takmičarskih nagrada u okviru saradnje sa
        betkotip.com, naš sajt/platforma i mobilna aplikacija, za vas imaju
        specijalnu ponudu !
      </p>

      <p>
        Kompletan iznos ulaganja koji biste uložili u naše takmičarske igre i u
        saradnju sa betkotip.com pod regulisanim i definisanim uslovima iste u
        obostranom interesu i u interesu korisnika i posetilaca našeg sajta, i
        kroz koji biste stimulisali posetioce i korisnike betkotip.com da
        učestvuju u istima, predviđen je tako da za uzvrat od nas za isti
        dobijate duplo više marketinškog prostora, u skladu sa trenutnim cenama
        istog u trenutku vašeg angažovanja, pod svim povoljnostima koje
        uključuju sve navedeno, praktično, potpuno besplatno ! Da, dobro ste
        pročitali, potpuno besplatno !
      </p>
      <h2>DA LI JOŠ UVEK IMATE DILEMU ?</h2>

      <p>Što nam se pre javite, cena za vas može biti samo bolja !</p>

      <p>
        Dobijate ogroman marketinški prostor na sajtu koji jasno pretenduje da
        vrlo brzo postane ozbiljna i veoma posećena društvena mreža ! Prva na
        Balkanu, i jedna od vrlo retkih na svetu, u oblasti sporta i prognoze
        ishoda sportskih događaja !
      </p>

      <p>
        I na kraju, da... Kao što smo istakli... Marketinški prostor na našem
        sajtu možete dobiti i potpuno besplatno, kroz saradnju sa nama...
      </p>

      <h2>DA LI JE TO SADA ZAISTA SVE !? NE, NIJE !</h2>

      <p>
        Ukoliko na bilo koji način delujete u oblasti "Bettinga", nudimo Vam i
        mnogo više od svega navedenog ! Ali hajde da to ostavimo za kontakt o
        saradnji i o sklapanju iste...
      </p>

      <p className={classes.blueP}>
        NE ČEKAJTE DA DRUGI BUDU BRŽI OD VAS ! KONTAKTIRAJTE NAS !
      </p>

      <p className={classes.email}>betko.prodaja@gmail.com</p>
    </div>
  )
}

export default Marketing
